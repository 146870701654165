import NumberFormatter from '../number_formatter'

const UNIT_MAPPINGS = {
  fahrenheit: '°F',
  celsius: '°C',
  rh: '%RH',
  inches_h2o: ' inWC',
  mmhg: ' mmHg',
  psi: ' PSI',
  pa: ' Pa',
  milliamps: ' mA',
  percent: '%CO₂',
  lx: ' Lx',
}

const PRECISIONS = {
  'Channel::AnalogPosition': 1,
  'Channel::Boolean': 0,
  'Channel::DifferentialPressure': 3,
  'Channel::GasConcentration': 1,
  'Channel::Light': 1,
  'Channel::RelativeHumidity': 1,
  'Channel::Temperature': 1,
}

const FORMATTER = new NumberFormatter()

export default class Formatter {
  static formatUnit(unit) {
    return UNIT_MAPPINGS[unit] || ''
  }

  static precision(type) {
    return PRECISIONS[type] || 1
  }

  static formatter(type, unit) {
    if (type === 'Channel::Boolean') {
      return function() {
        if (Math.round(this.value) === 0) {
          return 'Closed'
        } else {
          return 'Open'
        }
      }
    } else {
      return function() {
        if (this.value || this.value === 0) {
          const precision = PRECISIONS[type] || 1
          const formattedValue = FORMATTER.format(this.value, precision)
          const displayUnit = UNIT_MAPPINGS[unit] || ` ${unit}` || ''

          return `${formattedValue}${displayUnit}`
        } else {
          return '-'
        }
      }
    }
  }
}
