import moment from 'moment-timezone/builds/moment-timezone-with-data'

import 'moment/dist/locale/fr'
import 'moment/dist/locale/es'

moment.locale('en')

// NOTE: This is required by the `daterangepicker` library. Since that plugin
// references `moment` directly without any imports or dependencies, we need it
// to exist in the global context, unfortunately.
window.moment = moment

export default moment
