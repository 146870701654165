import _ from 'underscore'
import moment from '~/moment'
import Formatter from './formatter'

const UNIT_ALIASES = {
  f: 'fahrenheit',
  c: 'celsius',
}

const CONVERSION_MAPPINGS = {
  fahrenheit: 'fahrenheit',
  celsius: 'celsius',
  inches_h2o: 'inAq',
  mmhg: 'mmHg',
  psi: 'psi',
  pa: 'Pa',
  pascal: 'Pa',
}

const UNIVERSAL_RANGES = {
  volts: {low: 0.0, high: 10.0},
  milliamps: {low: 4.0, high: 20.0},
}

// Datapoint is a small utility wrapper for describing a single point of data
// in a larger series.
class Datapoint {
  constructor(value, formatter, ts) {
    this.format = this.format.bind(this)

    this.value = value
    this.formatter = formatter
    this.ts = ts
  }

  // Public: Format the datapoint value and unit.
  //
  // Returns a String.
  format() {
    if (this.value === null) {
      return '-'
    }
    return this.formatter.call(this)
  }

  // Public: Format the datapoint timestamp.
  //
  // Returns an String
  formatTime() {
    return moment(new Date(this.ts)).format(momentDateTimeFormat)
  }
}

// Channel encapsulates the logic for describing a series of data returned by the
// datapoint service.
export default class Channel {
  // Public: Initialize a new Channel object.
  //
  // summary     - An Object containing record metadata.
  // records     - An Array<Object> where each object represents a single datapoint.
  // metadata    - An Object containing channel display metadata.
  constructor(summary, records, metadata) {
    this.metadata = this.metadata.bind(this)
    this.reporting = this.reporting.bind(this)
    this.data = this.data.bind(this)
    this.formattedUnit = this.formattedUnit.bind(this)
    this.precision = this.precision.bind(this)
    this.formatter = this.formatter.bind(this)
    this.lastDatapoint = this.lastDatapoint.bind(this)
    this.values = this.values.bind(this)
    this._convert = this._convert.bind(this)
    this._normalize = this._normalize.bind(this)
    this._isConvertible = this._isConvertible.bind(this)
    this._point = this._point.bind(this)

    this.summary = summary
    this.records = records

    this.number = metadata.id
    this.id = `channel-${this.number}`
    this.analogRange = metadata.analog_range
    this.type = metadata.type
    this.name = metadata.name
    if (this.summary.unit) {
      this.unit = this._normalize(this.summary.unit)
    }

    if (this.analogRange) {
      this.displayUnit = this.analogRange.unit
    } else if (metadata.converted_unit || this.unit) {
      this.displayUnit = this._normalize(metadata.converted_unit || this.unit)
    }
  }

  // Public: Get a summary of the datapoint stream's analysis metadata.
  //
  // Returns an Object.
  metadata() {
    return {
      avg: this._point(this.summary.avg),
      max: this.max(),
      min: this.min(),
      mkt: this._point(this.summary.mkt),
    }
  }

  // Public: Get the channel's min point.
  //
  // Returns an Object.
  min() {
    return this._point(this.summary.min, this.summary.min_ts)
  }

  // Public: Get the channel's max point.
  //
  // Returns an Object.
  max() {
    return this._point(this.summary.max, this.summary.max_ts)
  }

  // Public: Do the records in this Channel include the requested attribute?
  //
  // key - A String attribute name. Accepted values are "value", "max", or "min".
  //
  // Returns a Boolean.
  reporting(key) {
    return _.has(this.records[0], key || 'value')
  }

  // Public: Get a list of timestamp, value tuples for the specified attribute in
  // the collection of Channel data.
  //
  // key - A String attribute name. Accepted values are "value", "max", or "min".
  //
  // Returns an Array.
  data(key) {
    key ||= 'value'

    return _.map(this.records, (record) => {
      const datapoint = this._point(record[key], record.ts)
      return [datapoint.ts, datapoint.value]
    })
  }

  // Public: Get a formatted version of the channel's unit.
  //
  // Returns a String.
  formattedUnit() {
    return Formatter.formatUnit(this.displayUnit)
  }

  // Public: Get the expected rounding precision for datapoints on this channel.
  //
  // Returns a Numeric value.
  precision() {
    return Formatter.precision(this.type)
  }

  // Public: Get a formatter to properly render datapoints.
  //
  // Returns a Function.
  formatter() {
    return this._formatter || (this._formatter = Formatter.formatter(this.type, this.displayUnit))
  }

  // Public: Get the most recent reading from this channel's data series.
  //
  // Returns a Datapoint or null.
  lastDatapoint() {
    const lastRecord = this.records[this.records.length - 1]
    if (lastRecord) {
      return this._point(lastRecord.value, lastRecord.ts)
    }
  }

  // Public: Get an array of datapoint values.
  //
  // Returns an Array<Integer>
  values() {
    return _.map(this.records, (record) => this._convert(record.value))
  }

  // Internal: Convert the passed value to the display unit configured for this
  // channel.
  //
  // value - A Numeric value.
  //
  // Returns a Numeric value.
  _convert(value) {
    if (!value && value !== 0) {
      return
    }

    let converted
    if (this._isMapped()) {
      const range = this.analogRange.high_value - this.analogRange.low_value
      const {low, high} = UNIVERSAL_RANGES[this.unit]
      converted = ((value - low) / (high - low)) * range + this.analogRange.low_value
    } else if (this._isConvertible()) {
      const point = math.unit(value, CONVERSION_MAPPINGS[this.unit])
      converted = point.toNumeric(CONVERSION_MAPPINGS[this.displayUnit])
    } else {
      converted = value
    }

    return parseFloat(converted.toFixed(this.precision()))
  }

  // Internal: Convert the passed unit to a standardized representation of that
  // unit.
  //
  // unit - A String unit name.
  //
  // Returns a String.
  _normalize(unit) {
    const normalized = unit.trim().toLowerCase().replace(' ', '_')
    return UNIT_ALIASES[normalized] || normalized
  }

  // Internal: Can a unit conversion be performed for this channel?
  //
  // Returns a Boolean.
  _isConvertible() {
    return (this.unit != null) &&
      (this.unit !== this.displayUnit) &&
      _.has(CONVERSION_MAPPINGS, this.unit) &&
      _.has(CONVERSION_MAPPINGS, this.displayUnit)
  }

  // Internal: Should this channel be interpreted using a mapped analog range?
  //
  // Returns a Boolean.
  _isMapped() {
    return (this.unit != null) && (this.analogRange != null)
  }

  // Internal: Wrap the passed value in a Datapoint object.
  //
  // value     - The value to wrap.
  // ts        - An Integer timestamp (default: null).
  //
  // Returns a Datapoint.
  _point(value, ts = null) {
    return new Datapoint(this._convert(value), this.formatter(), ts * 1000)
  }
}
