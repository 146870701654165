export default class NumberFormatter {
  // Public: Initialize a new NumberFormatter object.
  //
  // decimalSeparatorChar - A String containing the decimal separator to use.
  //                        Defaults to the user's preferred decimal separator.
  constructor(decimalSeparatorChar) {
    this._decimalSeparator = decimalSeparatorChar
  }

  // Public: Get the decimal separator character for this formatter object. This
  // allows us to lazily evaluate the value of `window.decimalSeparator`, in the
  // event that it changes after page load.
  //
  // Returns a String.
  get decimalSeparator() {
    return this._decimalSeparator || window.decimalSeparator || '.'
  }

  // Public: Format a number for display using a predefined decimal separator.
  //
  // value     - A Number or String value.
  // precision - The number of decimal places to display. Defaults to `null`,
  //             which will display the number as-is.
  //
  // Returns a String.
  format(value, precision = null) {
    const number = parseFloat(value)
    if (isNaN(number)) {
      throw new Error(`Unexpected value: ${typeof value}`)
    }

    const asString = precision === null ? number.toString() : number.toFixed(precision)

    return asString.replace('.', this.decimalSeparator)
  }

  // Public: Parse a number from a String using a predefined decimal separator.
  //
  // value - A String value containing a number with a decimal separator.
  //
  // Returns a Number or null if the value could not be parsed
  parse(value) {
    const asString = value.replace(this.decimalSeparator, '.')
    return parseFloat(asString) || null
  }
}
