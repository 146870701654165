import Color from 'color'
import _ from 'underscore'
import Highcharts from '~/highcharts'

import Chart from './chart'
import Formatter from './formatter'

const ORDERED_AXES = [
  'Channel::Temperature',
  'Channel::RelativeHumidity',
  'Channel::DifferentialPressure',
  'Channel::AnalogPosition',
  'Channel::GasConcentration',
  'Channel::Light',
]

const PRECISIONS = {
  'Channel::DifferentialPressure': 0.001,
  'Channel::GasConcentration': 0.001,
}

// SharedChart encapsulates the logic for adding and manipulating multiple series
// of channel data on one graph. This behavior will eventually be deprecated.
export default class SharedChart extends Chart {
  // Public: Initialize a new DeviceChart.
  //
  // options - An Object with the following attributes:
  //           title          - A String representing the chart's primary title.
  //           containerId    - The String ID of the container element in which
  //                            the chart should be rendered.
  //           channels       - A map of Objects representing the channels that
  //                            should be represented in the graph. Each Object
  //                            should have these attributes:
  //                              color - A String containing a CSS RGB value
  //                              display - A Boolean for whether the channel
  //                                        should be shown by default
  //                              converted_unit - A String containing the unit
  //                                               for display
  //           chartOptions   - Additional options that should be passed to
  //                            Highcharts (default: {}).
  //           timezoneOffset - An Integer representing the timezone offset in
  //                            minutes from UTC (default: 0).
  //           sync           - A Function to invoke when the chart should be
  //                            synchronized.
  constructor(options) {
    super(options)

    this._chart = new Highcharts.StockChart(this._init(options))
  }

  // Public: Add any available data and downsample metadata series to the chart,
  // given a passed datapoint service payload. Store the created series together
  // in the channelSeries data structure.
  //
  // NOTE: Will only add downsampling series when max/min values are present in
  // the records payload.
  //
  // channel - A Channel.
  //
  // Returns an Array of Highcharts series.
  setData(channel) {
    return this._channels[channel.number].series = _.compact([
      this._series(channel, 'min'),
      this._series(channel, 'max'),
      this._series(channel),
    ])
  }

  // Public: Add the passed list of flag series data to the chart.
  //
  // flags - An Array of Objects describing Highcharts series.
  //
  // Returns nothing.
  addFlags(flags) {
    for (const flag of flags) {
      let series
      const channelNum = parseInt(flag.onSeries.split('-')[1], 10)
      const channel = this._channels[channelNum]

      if (channel) {
        flag.visible = channel.settings.visible
      }
      if (channel || (channelNum === 0)) {
        series = this._chart.addSeries(flag, false)
      }

      if (channel != null) {
        channel.flags.push(series)
      }
    }

    return this._chart.redraw()
  }

  // Public: Clear all existing series from the chart.
  //
  // Returns nothing.
  reset() {
    for (const _channel in this._channels) {
      const data = this._channels[_channel]
      data.series = []
      data.flags = []
    }

    return this._reset()
  }

  // Public: Toggle the visibility of the specified channel.
  //
  // channel - A String identifier for the channel to toggle.
  //
  // Returns nothing.
  toggleChannel(channelNum) {
    channelNum = parseInt(channelNum, 10)
    const visible = !this._channels[channelNum].settings.visible
    this._channels[channelNum].settings.visible = visible

    for (const series of this._channels[channelNum].series) {
      series.setVisible(visible, false)
    }
    for (const flag of this._channels[channelNum].flags) {
      flag.setVisible(visible, false)
    }
    return this._chart.redraw()
  }

  // Internal: Build the options object for the chart.
  //
  // options - The options Object passed to the constructor.
  //
  // Returns an Object.
  _init(options) {
    this.setData = this.setData.bind(this)
    this.addFlags = this.addFlags.bind(this)
    this.reset = this.reset.bind(this)
    this.toggleChannel = this.toggleChannel.bind(this)
    this._color = this._color.bind(this)
    this._axisNumber = this._axisNumber.bind(this)
    this._axis = this._axis.bind(this)
    this._visible = this._visible.bind(this)

    this.channelNums = []
    this._channels = options.channels

    const _displayUnits = {}

    for (const number in this._channels) {
      const channel = this._channels[number]
      this.channelNums.push(number)
      _displayUnits[channel.type] = channel.converted_unit
      channel.series = []
      channel.flags = []
      channel.settings = {visible: channel.display}
    }

    this.axes = {}
    const validAxes = _.filter(ORDERED_AXES, (type) => _displayUnits[type])
    for (let index = 0; index < validAxes.length; index++) {
      const type = validAxes[index]
      this.axes[_displayUnits[type]] = index
    }

    this.options = _.extend(this.options, {yAxis: this._axes(_displayUnits)})

    return this.options
  }

  // Public: Look up color for a given channel.
  //
  // channel   - A String representing the ID of the Channel for which the color
  //             should be fetched.
  // secondary - A Boolean reflecting whether or not to lighten the color.
  //
  // Returns an RGB string.
  _color(channel, secondary) {
    if (secondary == null) {
      secondary = false
    }
    const channelNum = parseInt(channel, 10)
    let color = new Color(this._channels[channelNum].color)
    if (secondary) {
      color = this._lighten(color)
    }

    return color.rgb().string()
  }

  // Internal: Get a list of Y-Axes for the chart, based on the units that are
  // expected to be displayed. Renders a separate axis for Temperature, Humidity,
  // and Pressure channels, in that order, when present.
  //
  // displayUnits - A Hash mapping unit types to expected display units.
  //
  // Returns an Array<Object>.
  _axes(displayUnits) {
    const axisList = []

    for (const type of ORDERED_AXES) {
      if (displayUnits[type] != null) {
        const options =
          axisList.length < 1 ?
            {opposite: false, gridLineWidth: 1, gridLineColor: '#eee', labelAlign: 'right'} :
            {opposite: true, gridLineWidth: 0, gridLineColor: '#ccc', labelAlign: 'left'}

        axisList.push({
          opposite: options.opposite,
          gridLineWidth: options.gridLineWidth,
          gridLineColor: options.gridLineColor,
          min: options.min,
          max: options.max,
          maxPadding: 0.2,
          minPadding: 0.2,
          showLastLabel: true,
          minTickInterval: PRECISIONS[type] || 0.1,
          labels: {
            align: options.labelAlign,
            formatter: Formatter.formatter(type, displayUnits[type]),
          },
        })
      }
    }

    return axisList
  }

  // Internal: Get the index of the y-axis for this specific channel.
  //
  // channel - An Integer channel number.
  //
  // Returns an Integer.
  _axisNumber(channel) {
    return this.axes[this._channels[channel].converted_unit]
  }

  // Internal: Get the appropriate y-axis value for the channel identified by
  // the passed channel number.
  //
  // channel - An Integer channel number.
  //
  // Returns an Integer.
  _axis(channel) {
    return (this._channels[channel].series[0] != null ? this._channels[channel].series[0].yAxis : undefined)
  }

  // Internal: Is the specified channel currently visible?
  //
  // channel - An Integer channel number.
  //
  // Returns a Boolean.
  _visible(channel) {
    return this._channels[channel].settings.visible
  }
}
