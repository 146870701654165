import _ from 'underscore'
import Highcharts from '~/highcharts'
import moment from '~/moment'

import mixin from '../../helpers/mixin'
import EventsMixin from '../../mixins/events_mixin'

// Chart encapsulates the common logic for adding and manipulating data
// displayed on a readings graph.
class Chart {
  // Public: Initialize a new DeviceChart.
  //
  // options - An Object with the following attributes:
  //           title          - A String representing the chart's primary title.
  //           containerId    - The String ID of the container element in which
  //                            the chart should be rendered.
  //           channels       - A map of Objects representing the channels that
  //                            should be represented in the graph. Each Object
  //                            should have these attributes:
  //                              color - A String containing a CSS RGB value
  //                              display - A Boolean for whether the channel
  //                                        should be shown by default
  //                              display_unit - A String containing the unit for
  //                                             display
  //           chartOptions   - Additional options that should be passed to
  //                            Highcharts (default: {}).
  //           timezoneOffset - An Integer representing the timezone offset in
  //                            minutes from UTC (default: 0).
  //           sync           - A Function to invoke when the chart should be
  //                            synchronized.
  constructor(options) {
    this.removeFlags = this.removeFlags.bind(this)
    this._reset = this._reset.bind(this)
    this.load = this.load.bind(this)
    this._updateSubtitle = this._updateSubtitle.bind(this)
    this._subtitle = this._subtitle.bind(this)
    this._getChannel = this._getChannel.bind(this)
    this._series = this._series.bind(this)

    const global = options.timezoneOffset ? {timezoneOffset: options.timezoneOffset} : {useUTC: false}

    Highcharts.setOptions({
      global,
      credits: {
        enabled: false,
      },
    })

    this.title = options.title
    this.granularity = null

    if (options.chartOptions == null) {
      options.chartOptions = {}
    }

    this.options = _.extend(options.chartOptions, {
      title: {
        text: this.title,
      },
      subtitle: {
        text: this._subtitle(),
        style: {
          'font-size': '11px',
          'font-style': 'oblique',
          'color': '#aaa',
        },
      },
      chart: {
        marginBottom: 0,
        marginLeft: 80,
        renderTo: options.containerId,
        zoomType: 'x',
      },
      plotOptions: {
        series: {
          boostThreshold: 8700, // This is enough for 24 hours at 10 seconds/point
          dataGrouping: {
            enabled: false,
          },
          events: {
            click: (e) => this.triggerHandler('click', e),
          },
          states: {
            hover: {
              lineWidth: 2,
            },
          },
        },
      },
      series: [],
      navigator: {
        enabled: false,
      },
      tooltip: {
        borderColor: '#aaa',
        outside: false,
        shared: true,
        useHTML: true,
        formatter: this._tooltip,
      },
      rangeSelector: {
        enabled: false,
      },
      scrollbar: {
        liveRedraw: false,
        showFull: false,
      },
      xAxis: {
        ordinal: false,
        gridLineWidth: 1,
        gridLineColor: '#ddd',
        minPadding: 0.02,
        maxPadding: 0.02,
        events: {
          setExtremes: options.sync,
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            symbol: 'tridot',
          },
        },
      },
    })
  }

  // Public: Remove the passed list of flag series data from the chart.
  //
  // flags - An Array of Objects describing Highcharts series.
  //
  // Returns nothing.
  removeFlags(flags) {
    for (const flag of flags) {
      const flagSeries = this._chart.get(flag.data[0].id)
      if (flagSeries != null) {
        flagSeries.remove(false)
      }
    }

    this._chart.redraw()
  }

  // Public: Clear all existing series from the chart.
  //
  // Returns nothing.
  _reset() {
    for (let i = this._chart.series.length - 1; i >= 0; i--) {
      const series = this._chart.series[i]
      series.remove(false)
    }
    this._chart.redraw()
  }

  // Public: Reset the chart and display the loading indicator, hiding it once
  // the passed request promise has resolved.
  //
  // promise - A Promise.
  //
  // Returns a Promise.
  load(promise) {
    this.reset()
    this._chart.showLoading()

    return promise
        .then(() => this._chart.hideLoading())
        .catch((errorData) => {
        // For some reason when datapoint service fails with a 504, the second condition is what the request returned
          if ((errorData.status === 504) || ((errorData.status === 0) && (errorData.statusText === 'error'))) {
            const errorMsg = 'Loading data for the selected time period has timed out. Please try again or choose a shorter time interval'
            new FlashMessage(errorMsg, 'error', {keepMessage: true, icon: 'error'})
          }
        })
  }

  // Public: Get the minimum timestamp present on the chart.
  //
  // Returns an Integer.
  minTime() {
    return this._chart.xAxis[0].min
  }

  // Public: Get the maximum timestamp present on the chart.
  //
  // Returns an Integer.
  maxTime() {
    return this._chart.xAxis[0].max
  }

  // Public: Add horizontal plot line to the chart. Will remove any existing
  // plot lines with the same ID.
  //
  // referenceLine - An Object with `id`, `value`, and `channel` attributes
  //
  // Returns nothing.
  addOrUpdatePlotLine(referenceLine) {
    this.removePlotLine(referenceLine)

    const yAxis = this._axis(referenceLine.channel)
    if (!yAxis) {
      return
    }

    const color = this._color(referenceLine.channel)
    yAxis.addPlotLine({
      id: referenceLine.id,
      value: referenceLine.value,
      width: 1,
      color,
      dashStyle: 'dash',
      zIndex: 1,
      label: {
        text: referenceLine.label,
        x: 50,
        style: {
          color,
        },
      },
    })
  }

  // Public: Remove plot line from the chart.
  //
  // referenceLine - An Object with an `id` attribute
  //
  // Returns nothing.
  removePlotLine(referenceLine) {
    this._chart.yAxis[0].removePlotLine(referenceLine.id)
  }

  // Public: Set the granularity at which the chart's data is expected to be
  // displayed. Updates the chart subtitle to reflect any downsampling.
  //
  // value - An Integer reflecting the granularity to set (in seconds).
  //
  // Returns nothing.
  setGranularity(value) {
    this.granularity = value
    this._updateSubtitle()
  }

  // Internal: Update the chart's subtitle to reflect the currently-set
  // granularity.
  //
  // Returns nothing.
  _updateSubtitle() {
    this._chart.setTitle({}, {text: this._subtitle()}, false)
  }

  // Internal: Get a readable description of the chart's currently-set
  // downsampling rate.
  //
  // Returns a String.
  _subtitle() {
    if (!this.title) {
      return null
    }
    if (!this.granularity) {
      return ''
    }

    const resolution =
      this.granularity <= 60 ?
        `${this.granularity}sec` :
      this.granularity <= 3600 ?
        `${parseInt(this.granularity / 60)}min` :
        `${(this.granularity / 3600)}hr`

    return `Showing at ${resolution} resolution`
  }

  // Public: Render chart as an SVG.
  //
  // options - An Object with options to be passed to Highcharts chart.
  //           (See http://api.highcharts.com/highstock/Chart.getSVG)
  //
  // Returns a String.
  getSVG(options) {
    return this._chart.getSVG(options)
  }

  // Internal: Get the matching channel for a set of flag data.
  //
  // flagData - An Object with an `onSeries` attribute.
  //
  // Returns a channel Object or undefined.
  _getChannel(flagData) {
    let channelNum = flagData.onSeries.split('-')[1]
    channelNum = parseInt(channelNum, 10)
    return this._channels[channelNum]
  }

  // Internal: Generate a series for the passed combination of channel data and
  // specified value key. Does nothing if the specified key is not present in the
  // collection of records.
  //
  // channel  - A Channel.
  // valueKey - A String representing the attribute for which values should be
  //            fetched.
  //
  // Returns a Highcharts series or null.
  _series(channel, valueKey) {
    if (valueKey == null) {
      valueKey = 'value'
    }
    if (valueKey !== 'value' && !channel.reporting(valueKey)) {
      return null
    }

    let {id, name} = channel
    if (valueKey !== 'value') {
      id += `-${valueKey}`
      name += ` (${valueKey})`
    }

    return this._chart.addSeries({
      id,
      name,
      data: channel.data(valueKey),
      visible: this._visible(channel.number),
      color: this._color(channel.number, valueKey !== 'value'),
      yAxis: this._axisNumber(channel.number),
      extra: {
        formatter: channel.formatter(),
        precision: channel.precision(),
        unitSuffix: channel.formattedUnit(),
        channel: channel.number,
        source: valueKey,
      },
    })
  }

  // Internal: Lighten a given color. This takes into account the lightness of the
  // original to prevent white or near-white values.
  //
  // color - A Color object.
  //
  // Returns a Color.
  _lighten(color) {
    if (color.isLight()) {
      return color.lighten(0.2)
    } else {
      return color.lighten(0.5)
    }
  }

  // Internal: Generate a tooltip for the passed collection of points. Condense
  // minimum and maximum series into metadata for the base series.
  //
  // Returns an Array<String>.
  _tooltip() {
    let tooltips = []

    if (this.point?.series?.onSeries) {
      const text = _.escape(this.point.text)
      tooltips = [`<br /><span style='color:${this.point.series.onSeries.options.color}'>\u25CF</span> ${this.point.series.onSeries.name}: <b>${text}</b>`]
    } else if (this.point) {
      const text = _.escape(this.point.text)
      tooltips = [`<br /><b>${text}</b>`]
    } else {
      tooltips = _.chain(this.points)
          .map((point) => {
            if (point.series.options.id.includes('min')) {
              return false
            }
            if (point.series.options.id.includes('max')) {
              return false
            }

            const formatter = point.series.options.extra.formatter
            const tooltip = `<span style='color: ${point.color};'>\u25CF</span> ${point.series.name}: <b>${formatter.call({value: point.y})}</b>`
            const min = _.detect(point.series.chart.get(`${point.series.options.id}-min`)?.data || [], (p) => point.x == p.x)
            const max = _.detect(point.series.chart.get(`${point.series.options.id}-max`)?.data || [], (p) => point.x == p.x)

            if (min && max) {
              return `${tooltip} (${formatter.call({value: min.y})} / ${formatter.call({value: max.y})})`
            } else {
              return tooltip
            }
          })
          .value()
    }

    return [moment(this.x).format(momentDateTimeFormat)].concat(tooltips)
  }
}

mixin.include(Chart, EventsMixin)

export default Chart
