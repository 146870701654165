import Highcharts from 'highcharts/highstock'

import accessibility from 'highcharts/modules/accessibility'
import boost from 'highcharts/modules/boost'
import boostCanvas from 'highcharts/modules/boost-canvas'
import exporting from 'highcharts/modules/exporting'
import offlineExporting from 'highcharts/modules/offline-exporting'

accessibility(Highcharts)
boost(Highcharts)
boostCanvas(Highcharts)
exporting(Highcharts)
offlineExporting(Highcharts)

Highcharts.SVGRenderer.prototype.symbols.tridot = function(x, y, w, h) {
  const path = [
    'M', 12, 4,
    'a', 1, 1, 0, 1, 1, -0.55, 0,
    'z',
    'm', 0, 6,
    'a', 1, 1, 0, 1, 1, -0.55, 0,
    'z',
    'm', 0, 6,
    'a', 1, 1, 0, 1, 1, -0.55, 0,
    'z',
  ]
  return path
}

export default Highcharts
