const WORKER_COUNT = 6

class JobQueue {
  // Public: Initialize a new JobQueue, with an empty backlog and a pool of
  // workers.
  //
  // NOTE: The range of numbers in `_workers` starts at 1 because 0 evaluates as
  // a false value in Javascript. This allows us to use a simpler check for
  // worker availability.
  constructor(count) {
    this.add = this.add.bind(this)
    this._dequeue = this._dequeue.bind(this)
    this._process = this._process.bind(this)
    this._backlog = []
    this._workers = Array.from({length: count}, (_, i) => i + 1)
  }

  // Public: Add a job to be processed. If space is available, start processing
  // the job immediately; otherwise add it to the backlog to be picked up.
  //
  // job - A Function that returns a Promise when called.
  //
  // Returns a Promise.
  add(job) {
    return new Promise((resolve, reject) => {
      const worker = this._workers.shift()

      if (worker) {
        return this._process(job, worker, resolve, reject)
      } else {
        return this._backlog.push([job, resolve, reject])
      }
    })
  }

  // Internal: Pick up the next job from the queue, and assign it to the
  // specified worker. If no job is present in the queue, free the worker.
  //
  // worker - An Integer worker number.
  //
  // Returns nothing.
  _dequeue(worker) {
    const definition = this._backlog.shift()

    if (definition) {
      const [job, resolve, reject] = definition
      this._process(job, worker, resolve, reject)
    } else {
      this._workers.push(worker)
    }
  }

  // Internal: Attach the specified job to the specific worker, and invoke the
  // underlying Promise.
  //
  // job     - A Function that returns a Promise when called.
  // worker  - An Integer worker number.
  // resolve - A Function to invoke when the job has been successfully processed.
  // reject  - A Function to invoke when the job has failed to process.
  //
  // Returns a Promise.
  _process(job, worker, resolve, reject) {
    return job()
        .then(() => this._dequeue(worker)).then(resolve)
        .catch((data) => {
          this._dequeue(worker)
          reject(data)
        })
  }
}

// Initialize a "Singleton" for the job queue, to be shared for all DPS requests.
window.RequestQueue = new JobQueue(WORKER_COUNT)
export default window.RequestQueue
