export default {
  // Subscribe to an event
  on(...args) {
    return $(this).on(...args)[0]
  },

  // Unsubscribe from an event
  off(...args) {
    return $(this).off(...args)[0]
  },

  // Trigger an event
  trigger(...args) {
    return $(this).trigger(...args)
  },

  // Trigger an event handler
  triggerHandler(...args) {
    return $(this).triggerHandler(...args)
  },
}
