const RANGES = {
  '15min': 60 * 15,
  '1h': 60 * 60,
  '4h': 60 * 60 * 4,
  '12h': 60 * 60 * 12,
  '24h': 60 * 60 * 24,
  '7d': 60 * 60 * 24 * 7,
  '1m': 60 * 60 * 24 * 30,
  '3m': 60 * 60 * 24 * 30 * 3,
  '6m': 60 * 60 * 24 * 30 * 6,
  '1y': 60 * 60 * 24 * 30 * 12,
}

// TimeRange contains a set of internal utilities for mapping time range
// descriptions to actual Integer timestamp values.
export default class TimeRange {
  // Public: Get the number of milliseconds represented by the passed time range
  // description.
  //
  // description - A String matching one of the keys in `RANGES`.
  //
  // Returns an Integer.
  static milliseconds(description) {
    return RANGES[description] * 1000
  }

  // Public: Get the expected downsampling granularity (in seconds) for a
  // collection of data spanning the time range matching the passed description.
  //
  // minTime - An Integer representing the minimum timestamp in milliseconds
  // maxTime - An Integer representing the maximum timestamp in milliseconds
  //
  // Returns an Integer or null.
  static granularity(minTime, maxTime) {
    const timeRange = (maxTime - minTime) / 1000

    if (timeRange >= RANGES['1y']) {
      return RANGES['24h']
    } else if (timeRange >= RANGES['3m']) {
      return RANGES['4h']
    } else if (timeRange >= RANGES['1m']) {
      return RANGES['1h']
    } else if (timeRange > RANGES['24h']) {
      return RANGES['15min']
    } else {
      return null
    }
  }
}
