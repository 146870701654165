import $ from 'jquery'

const mixin = {
  include(klass, ...mixins) {
    $.extend(klass.prototype, ...mixins)
  },
  extend(klass, ...mixins) {
    $.extend(klass, ...mixins)
  },
}

export default mixin
