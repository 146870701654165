import _ from 'underscore'

import Highcharts from '~/highcharts'
import SharedChart from './shared_chart'
import Formatter from './formatter'

// BooleanChart encapsulates the logic for adding and manipulating the data
// displayed for boolean channel's readings graph.
export default class BooleanChart extends SharedChart {
  // Public: Initialize a new BooleanChart.
  //
  // options - An Object with the following attributes:
  //           containerId    - The String ID of the container element in which
  //                            the chart should be rendered.
  //           channels       - A map of Objects representing the channels that
  //                            should be represented in the graph. Each Object
  //                            should have these attributes:
  //                              color - A String containing a CSS RGB value
  //                              display - A Boolean for whether the channel
  //                                        should be shown by default
  //                              converted_unit - A String containing the unit
  //                                               for display
  //           chartOptions   - Additional options that should be passed to
  //                            Highcharts (default: {}).
  //           timezoneOffset - An Integer representing the timezone offset in
  //                            minutes from UTC (default: 0).
  //           sync           - A Function to invoke when the chart should be
  //                            synchronized.
  constructor(options) {
    super(options)

    this._formatter = Formatter.formatter('Channel::Boolean', 'boolean')

    this.options = _.extend(this._init(options), {yAxis: [this._yAxis()]})
    this._chart = new Highcharts.StockChart(this.options)
  }


  // Internal: Get the index of the y-axis for boolean channels.
  //
  // Returns an Integer.
  _axisNumber() {
    return 0
  }

  // Internal: Get an appropriate Y-Axis for the chart, based on the type of
  // channel that is expected to be displayed. Checkout out the Highchart options for yAxis.
  //
  // Returns an Object.
  _yAxis() {
    return {
      tickPositions: [0, 1],
      showLastLabel: true,
      showFirstLabel: true,
      endOnTick: false,
      startOnTick: false,
      min: 0,
      max: 1.2,
      opposite: false,
      gridLineWidth: 1,
      gridLineColor: '#eee',
      maxPadding: 0.25,
      minPadding: 0.25,
      labels: {
        align: 'right',
        formatter: this._formatter,
      },
    }
  }
}
