import $ from 'jquery'
import _ from 'underscore'

import Channel from './channel'
import RequestQueue from '../request_queue'

// DatapointRequester encapsulates the logic for making requests to the datapoint
// service for data on a specified device channel within a specified time range.
export default class DatapointRequester {
  // Public: Initialize a new DatapointRequester.
  //
  // baseUrl     - A String representing a fully-qualified URL for the datapoint
  //               service.
  // channels    - A map of Objects representing the channels that should be
  //               fetched from the service. Each Object should have these attributes:
  //                 display_unit - A String containing the unit for display
  constructor(_baseUrl, _channels) {
    this.fetch = this.fetch.bind(this)
    this._baseUrl = _baseUrl
    this._channels = _channels
  }

  // Public: Fetch all data for the specified channel within the specified time
  // range, in the context of a Promise which is resolved on a successful
  // response from the datapoint service.
  //
  // minTime     - An Integer representing the earliest timestamp (in
  //               milliseconds) for which data should be fetched.
  // maxTime     - An Integer representing the latest timestamp (in milliseconds)
  //               for which data should be fetched.
  // granularity - An Integer representing the downsampling time range. If null,
  //               non-downsampled data will be fetched.
  // onSuccess   - A Function that should be invoked with each successful request
  //               to the datapoint service.
  //
  // Returns a Promise.
  fetch(minTime, maxTime, granularity, onSuccess) {
    const requests = _.pairs(this._channels).map((...args) => {
      const metadata = args[0][1]

      return RequestQueue.add(() => {
        return new Promise((resolve, reject) => {
          $.ajax({
            url: this._url(metadata.uuid, minTime, maxTime, granularity),
            success: (data) => {
              const channel = new Channel(data.summary, data.records, metadata)
              onSuccess(channel)
              resolve(data)
            },
            error: (data) => {
              reject(data)
            },
          })
        })
      })
    })

    return Promise.all(requests)
  }

  // Internal: Get the appropriate URL for the specified attributes.
  //
  // channel     - A String channel UUID.
  // minTime     - An Integer representing the earliest timestamp (in
  //               milliseconds) for which data should be fetched.
  // maxTime     - An Integer representing the latest timestamp (in milliseconds)
  //               for which data should be fetched.
  // granularity - An Integer representing the downsampling time range. If null,
  //               non-downsampled data will be fetched.
  //
  // Returns a String.
  _url(channel, minTime, maxTime, granularity) {
    return _.compact([
      this._baseUrl,
      'datapoints',
      channel,
      this._timestamp(minTime),
      this._timestamp(maxTime),
      granularity,
    ]).join('/')
  }

  // Internal: Convert the passed milliseconds timestamp to a standard timestamp
  // (in seconds).
  //
  // time - An Integer representing a specific timestamp (in milliseconds).
  //
  // Returns an Integer.
  _timestamp(time) {
    return Math.round(time / 1000)
  }
}
